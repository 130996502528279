import {
	Avatar,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Icon,
	IconButton,
	Link as LinkMat,
	Stack,
	Typography,
} from '@mui/material';
import {
	CSSProperties,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/system';
import { BigNumber } from 'ethers';
import { AppContext } from '../contexts';
import TokenAmountBox from './TokenAmountBox';
import { CHAIN_MAP, format, formatAddress } from '../web3/web3Utils';
import XLogo from './XLogo';
import { getUserDisplayName } from '../pages/Profile/utils';
import Accordion from './Accordion';

import logo from '../assets/demos-full-logo.png';
import discordIconBlack from '../assets/discord.svg';
import walletIconWhite from '../assets/wallet.svg';

import MenuIcon from '@mui/icons-material/Menu';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CloseIcon from '@mui/icons-material/Close';

const Sidebar = ({
	handleChangeNetwork,
}: {
	handleChangeNetwork: (value: number) => void;
}): JSX.Element => {
	const kolDisabled = process.env.REACT_APP_KOL_DISABLED === 'true';

	const location = useLocation();

	const [open, setOpen] = useState<boolean>(false);
	const [isAccountOpen, setIsAccountOpen] = useState<boolean>(false);

	const [balance, setBalance] = useState<BigNumber>(BigNumber.from(0));
	const [totalSupply, setTotalSupply] = useState<BigNumber>(
		BigNumber.from(0)
	);
	const [wasBalanceFetched, setWasBalanceFetched] = useState(false);
	const [wasSupplyFetched, setWasSupplyFetched] = useState(false);

	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [inOverview, setInOverview] = useState(false);

	const navigate = useNavigate();

	const { isAuthenticated, chain, logoutUser, user, web3Client } =
		useContext(AppContext);

	const refreshBalance = useCallback(() => {
		if (user?.publicAddress && web3Client) {
			web3Client
				.getUserBalance(user?.publicAddress)
				.then((newBalance) => {
					if (!wasBalanceFetched) {
						setWasBalanceFetched(true);
					}
					setBalance(newBalance || balance);
				});
		}
	}, [user, web3Client]);

	useEffect(() => {
		if (web3Client && user?.isAdmin) {
			web3Client.getTotalSupply().then((newTotalSupply) => {
				if (!wasSupplyFetched) {
					setWasSupplyFetched(true);
				}
				setTotalSupply(newTotalSupply || BigNumber.from(0));
			});
		}
		refreshBalance();
		const interval = setInterval(refreshBalance, 30000);
		return () => clearInterval(interval);
	}, [user?.isAdmin, user?.publicAddress, web3Client]);

	const handleToggleDialog = () => {
		setIsDialogOpen((value) => !value);
	};

	if (!isAuthenticated)
		return (
			<Button
				startIcon={<AccountBalanceWalletIcon htmlColor="#55E37D" />}
				onClick={() => navigate('/login')}
				sx={{
					...styles.connectWalletButton,
				}}
			>
				<Typography
					style={{
						...styles.connectWalletButtonText,
					}}
				>
					{`Connect Wallet`}
				</Typography>
			</Button>
		);

	const getChainIcon = (chainId: number) => {
		return (
			<Box
				style={{
					...styles.dialogButtonIconCircle,
					background: CHAIN_MAP[chainId].icon.background,
				}}
			>
				<img
					src={CHAIN_MAP[chainId].icon.icon}
					height={chainId === 80085 ? 15 : 30}
					style={{
						padding:
							chainId === 1
								? '8px 13px'
								: chainId === 80085
								? '16px 7px'
								: chainId === 1115 || chainId === 1116
								? '8px 10px'
								: '8px',
					}}
				/>
			</Box>
		);
	};

	const isMediumDesktop = useMediaQuery((theme: Theme) =>
		theme.breakpoints.between('md', 'lg')
	);

	const isDesktop = useMediaQuery((theme: Theme) =>
		theme.breakpoints.up('md')
	);

	const is900to1400 = useMediaQuery((theme: Theme) =>
		theme.breakpoints.between(900, 1400)
	);

	const is1400to1600 = useMediaQuery((theme: Theme) =>
		theme.breakpoints.between(1400, 1600)
	);

	const handleCloseMenu = () => {
		setOpen(false);
	};

	const handleToggleAccount = (value: boolean) => {
		setIsAccountOpen(value);
	};

	const networkDialog = (
		<Dialog open={isDialogOpen}>
			<DialogTitle>
				<Stack
					direction={'row'}
					justifyContent={'space-between'}
					alignItems={'center'}
				>
					<Typography style={{ ...styles.dialogTitle }}>
						{`Choose Network`}
					</Typography>
					<IconButton onClick={() => handleToggleDialog()}>
						<CloseIcon htmlColor="#718096" />
					</IconButton>
				</Stack>
			</DialogTitle>
			<DialogContent>
				<Divider />

				<Button
					onClick={() => handleChangeNetwork(1)}
					sx={{
						...styles.dialogButton,
					}}
				>
					<Stack direction={'row'} alignItems={'center'} spacing={2}>
						{getChainIcon(1)}
						<Typography
							style={{
								...styles.dialogButtonText,
							}}
						>
							{CHAIN_MAP[1].name}
						</Typography>
					</Stack>
				</Button>

				<Divider />

				{user?.isAdmin || user?.isCampaignCreator ? (
					<>
						<Button
							onClick={() => handleChangeNetwork(80002)}
							sx={{
								...styles.dialogButton,
							}}
						>
							<Stack
								direction={'row'}
								alignItems={'center'}
								spacing={2}
							>
								{getChainIcon(80002)}
								<Typography
									style={{
										...styles.dialogButtonText,
									}}
								>
									{CHAIN_MAP[80002].name}
								</Typography>
							</Stack>
						</Button>
					</>
				) : null}

				<Divider />

				<Button
					onClick={() => handleChangeNetwork(42161)}
					sx={{
						...styles.dialogButton,
					}}
				>
					<Stack direction={'row'} alignItems={'center'} spacing={2}>
						{getChainIcon(42161)}
						<Typography
							style={{
								...styles.dialogButtonText,
							}}
						>
							{CHAIN_MAP[42161].name}
						</Typography>
					</Stack>
				</Button>

				<Divider />

				<Button
					onClick={() => handleChangeNetwork(137)}
					sx={{
						...styles.dialogButton,
					}}
				>
					<Stack direction={'row'} alignItems={'center'} spacing={2}>
						{getChainIcon(137)}
						<Typography
							style={{
								...styles.dialogButtonText,
							}}
						>
							{CHAIN_MAP[137].name}
						</Typography>
					</Stack>
				</Button>

				{user?.isAdmin || user?.isCampaignCreator ? (
					<>
						<Divider />

						<Button
							onClick={() => handleChangeNetwork(421614)}
							sx={{
								...styles.dialogButton,
							}}
						>
							<Stack
								direction={'row'}
								alignItems={'center'}
								spacing={2}
							>
								{getChainIcon(421614)}
								<Typography
									style={{
										...styles.dialogButtonText,
									}}
								>
									{CHAIN_MAP[421614].name}
								</Typography>
							</Stack>
						</Button>
					</>
				) : null}

				{/* <Divider />

				<Button
					onClick={() => handleChangeNetwork(1101)}
					sx={{
						...styles.dialogButton,
					}}
				>
					<Stack direction={'row'} alignItems={'center'} spacing={2}>
						{getChainIcon(1101)}
						<Typography
							style={{
								...styles.dialogButtonText,
							}}
						>
							{CHAIN_MAP[1101].name}
						</Typography>
					</Stack>
				</Button>

				{user?.isAdmin || user?.isCampaignCreator ? (
					<>
						<Divider />

						<Button
							onClick={() => handleChangeNetwork(2442)}
							sx={{
								...styles.dialogButton,
							}}
						>
							<Stack
								direction={'row'}
								alignItems={'center'}
								spacing={2}
							>
								{getChainIcon(2442)}
								<Typography
									style={{
										...styles.dialogButtonText,
									}}
								>
									{CHAIN_MAP[2442].name}
								</Typography>
							</Stack>
						</Button>
					</>
				) : null} */}

				<Divider />

				<Button
					onClick={() => handleChangeNetwork(80085)}
					sx={{
						...styles.dialogButton,
					}}
				>
					<Stack direction={'row'} alignItems={'center'} spacing={2}>
						{getChainIcon(80085)}
						<Typography
							style={{
								...styles.dialogButtonText,
							}}
						>
							{CHAIN_MAP[80085].name}
						</Typography>
					</Stack>
				</Button>

				{/* <Divider />

				<Button
					onClick={() => handleChangeNetwork(1116)}
					sx={{
						...styles.dialogButton,
					}}
				>
					<Stack direction={'row'} alignItems={'center'} spacing={2}>
						{getChainIcon(1116)}
						<Typography
							style={{
								...styles.dialogButtonText,
							}}
						>
							{CHAIN_MAP[1116].name}
						</Typography>
					</Stack>
				</Button>

				{user?.isAdmin || user?.isCampaignCreator ? (
					<>
						<Divider />

						<Button
							onClick={() => handleChangeNetwork(1115)}
							sx={{
								...styles.dialogButton,
							}}
						>
							<Stack
								direction={'row'}
								alignItems={'center'}
								spacing={2}
							>
								{getChainIcon(1115)}
								<Typography
									style={{
										...styles.dialogButtonText,
									}}
								>
									{CHAIN_MAP[1115].name}
								</Typography>
							</Stack>
						</Button>
					</>
				) : null}

				<Divider />

				<Button
					onClick={() => handleChangeNetwork(8453)}
					sx={{
						...styles.dialogButton,
					}}
				>
					<Stack direction={'row'} alignItems={'center'} spacing={2}>
						{getChainIcon(8453)}
						<Typography
							style={{
								...styles.dialogButtonText,
							}}
						>
							{CHAIN_MAP[8453].name}
						</Typography>
					</Stack>
				</Button>

				{user?.isAdmin || user?.isCampaignCreator ? (
					<>
						<Divider />

						<Button
							onClick={() => handleChangeNetwork(84532)}
							sx={{
								...styles.dialogButton,
							}}
						>
							<Stack
								direction={'row'}
								alignItems={'center'}
								spacing={2}
							>
								{getChainIcon(84532)}
								<Typography
									style={{
										...styles.dialogButtonText,
									}}
								>
									{CHAIN_MAP[84532].name}
								</Typography>
							</Stack>
						</Button>
					</>
				) : null} */}
			</DialogContent>
		</Dialog>
	);

	const joinMarketplaceWidget = (
		<>
			{false &&
			!location.pathname.includes('user') &&
			!(user?.isCampaignCreator || (user?.isKOL && !kolDisabled)) ? (
				<Stack spacing={2} style={{ ...styles.marketplaceWidget }}>
					<Typography
						style={{
							...styles.marketplaceWidgetTitle,
						}}
					>
						{`Become a brand`}
						{/* {`Join the marketplace`} */}
					</Typography>
					<Typography
						style={{
							...styles.marketplaceWidgetText,
						}}
					>
						{`Sign up as a brand to build your community hub and launch your campaigns!`}
						{/* {`Find out new way of managing your campaigns`} */}
					</Typography>
					<Button
						style={{
							...styles.marketplaceWidgetButton,
						}}
						onClick={() =>
							navigate(`/user/${user?.id}?joinAs=client`)
						}
					>
						<Typography
							style={{
								...styles.marketplaceWidgetButtonText,
							}}
						>
							{`Join now`}
						</Typography>
					</Button>
				</Stack>
			) : null}
		</>
	);

	const tokenBalancesWidgets = (
		<>
			{user?.isAdmin ? (
				<Stack>
					{wasSupplyFetched ? (
						<TokenAmountBox
							title={`Supply:`}
							value={format(totalSupply)}
						/>
					) : (
						<CircularProgress
							size={20}
							style={{
								...styles.spinner,
							}}
						/>
					)}
				</Stack>
			) : null}
			<Stack>
				{wasBalanceFetched ? (
					<TokenAmountBox
						title={`Balance:`}
						value={format(balance)}
					/>
				) : (
					<CircularProgress
						size={20}
						style={{
							...styles.spinner,
						}}
					/>
				)}
			</Stack>
		</>
	);

	return (
		<Stack
			padding={{ xs: '0 10px', sm: '0 105px', md: '0 5px', lg: '0 20px' }}
			justifyContent={'space-between'}
			sx={{
				...styles.container,
				minHeight: isDesktop || open ? '100%' : '5vh',
				position: isDesktop ? 'fixed' : 'relative',
				...(isDesktop
					? {
							width: '16.666667%',
							boxSizing: 'border-box',
							height: '100%',
							overflowY: 'auto',
					  }
					: {}),
			}}
			spacing={1}
		>
			<Stack>
				<Stack
					direction={{ xs: 'row', md: 'column' }}
					justifyContent={'space-between'}
					alignItems={{ xs: 'center', md: 'flex-start' }}
					sx={{
						padding: { xs: 0, md: '10px 12px', xl: '20px 12px' },
					}}
				>
					<Link to="/campaigns" onClick={handleCloseMenu}>
						<Avatar
							variant="square"
							sx={{
								width: {
									xs: '100px',
									md: '100px',
									lg: '125px',
								},
							}}
							src={logo}
							alt="postmint"
							imgProps={{
								style: {
									objectFit: 'contain',
								},
							}}
						/>
					</Link>
					{!isDesktop ? (
						<IconButton onClick={() => setOpen((prev) => !prev)}>
							<MenuIcon />
						</IconButton>
					) : null}
				</Stack>
				{isDesktop || open ? (
					<Stack spacing={{ xs: 1, md: 0.5, xl: 1 }}>
						<Link
							to={`/${
								user?.isAdmin || user?.isCampaignCreator
									? 'community'
									: 'user'
							}/${user?.id}`}
							onClick={() => {
								setInOverview(true);
								handleCloseMenu();
							}}
							style={{
								...styles.linkButton,
								...(inOverview &&
								location.pathname ===
									`/${
										user?.isAdmin || user?.isCampaignCreator
											? 'community'
											: 'user'
									}/${user?.id}`
									? styles.linkButtonActive
									: {}),
							}}
						>
							<Stack direction={'row'} spacing={2}>
								<LayersOutlinedIcon
									htmlColor={
										inOverview &&
										location.pathname ===
											`/${
												user?.isAdmin ||
												user?.isCampaignCreator
													? 'community'
													: 'user'
											}/${user?.id}`
											? '#49B68A'
											: '#98A2B3'
									}
								/>
								<Typography
									style={{
										...styles.linkText,
										...(inOverview &&
										location.pathname ===
											`/${
												user?.isAdmin ||
												user?.isCampaignCreator
													? 'community'
													: 'user'
											}/${user?.id}`
											? styles.linkActive
											: {}),
									}}
								>
									{`Overview`}
								</Typography>
							</Stack>
						</Link>
						<Link
							to={'/campaigns'}
							onClick={handleCloseMenu}
							style={{
								...styles.linkButton,
								...(location.pathname === '/' ||
								location.pathname === '/campaigns' ||
								(location.pathname.includes('/community') &&
									location.pathname.split('/').length === 4)
									? styles.linkButtonActive
									: {}),
							}}
						>
							<Stack direction={'row'} spacing={2}>
								<DateRangeOutlinedIcon
									htmlColor={
										location.pathname === '/' ||
										location.pathname === '/campaigns' ||
										(location.pathname.includes(
											'/community'
										) &&
											location.pathname.split('/')
												.length === 4)
											? '#49B68A'
											: '#98A2B3'
									}
								/>
								<Typography
									style={{
										...styles.linkText,
										...(location.pathname === '/' ||
										location.pathname === '/campaigns' ||
										(location.pathname.includes(
											'/community'
										) &&
											location.pathname.split('/')
												.length === 4)
											? styles.linkActive
											: {}),
									}}
								>
									{`Campaigns`}
								</Typography>
							</Stack>
						</Link>
						{false && (
							<Link
								to={'/communities'}
								onClick={() => {
									setInOverview(false);
									handleCloseMenu();
								}}
								style={{
									...styles.linkButton,
									...(location.pathname === '/communities' ||
									(!inOverview &&
										location.pathname.includes(
											'/community'
										) &&
										location.pathname.split('/').length ===
											3)
										? styles.linkButtonActive
										: {}),
								}}
							>
								<Stack direction={'row'} spacing={2}>
									<PeopleOutlineOutlinedIcon
										htmlColor={
											location.pathname ===
												'/communities' ||
											(!inOverview &&
												location.pathname.includes(
													'/community'
												) &&
												location.pathname.split('/')
													.length === 3)
												? '#49B68A'
												: '#98A2B3'
										}
									/>
									<Typography
										style={{
											...styles.linkText,
											...(location.pathname ===
												'/communities' ||
											(!inOverview &&
												location.pathname.includes(
													'/community'
												) &&
												location.pathname.split('/')
													.length === 3)
												? styles.linkActive
												: {}),
										}}
									>
										{`Communities`}
									</Typography>
								</Stack>
							</Link>
						)}
						{user?.isAdmin && (
							<Link
								to={'/marketplace'}
								onClick={handleCloseMenu}
								style={{
									...styles.linkButton,
									...(location.pathname === '/marketplace'
										? styles.linkButtonActive
										: {}),
								}}
							>
								<Stack direction={'row'} spacing={2}>
									<SellOutlinedIcon
										htmlColor={
											location.pathname === '/marketplace'
												? '#49B68A'
												: '#98A2B3'
										}
									/>
									<Typography
										style={{
											...styles.linkText,
											...(location.pathname ===
											'/marketplace'
												? styles.linkActive
												: {}),
										}}
									>
										{`Marketplace`}
									</Typography>
								</Stack>
							</Link>
						)}
						<Link
							to={'/profile'}
							onClick={handleCloseMenu}
							style={{
								...styles.linkButton,
								marginBottom: 1,
								...(location.pathname === '/profile'
									? styles.linkButtonActive
									: {}),
							}}
						>
							<Stack direction={'row'} spacing={2}>
								<SettingsOutlinedIcon
									htmlColor={
										location.pathname === '/profile'
											? '#49B68A'
											: '#98A2B3'
									}
								/>
								<Typography
									style={{
										...styles.linkText,
										...(location.pathname === '/profile'
											? styles.linkActive
											: {}),
									}}
								>
									{`Settings`}
								</Typography>
							</Stack>
						</Link>
						{user?.isCampaignCreator || user?.isAdmin ? (
							<Button
								component={Link}
								to="/campaigns/create"
								onClick={handleCloseMenu}
								sx={{
									...styles.createCampaignButton,
								}}
							>
								<Typography
									style={{
										...styles.createCampaignButtonText,
									}}
								>
									{`Create Campaign`}
								</Typography>
							</Button>
						) : null}
					</Stack>
				) : null}
			</Stack>
			{isDesktop || open ? (
				<Stack spacing={1}>
					{!isDesktop && !isMediumDesktop
						? joinMarketplaceWidget
						: !isAccountOpen
						? joinMarketplaceWidget
						: null}
					<Stack spacing={1}>
						{!isDesktop && !isMediumDesktop
							? tokenBalancesWidgets
							: !isAccountOpen
							? tokenBalancesWidgets
							: null}
						{user ? (
							<Accordion
								heading={
									<Stack
										direction={'row'}
										alignItems={'center'}
										spacing={1}
									>
										<Button
											sx={{
												...styles.accountButton,
												background: isAuthenticated
													? 'radial-gradient(93.43% 794.53% at 16.45% 6.57%, rgba(85, 255, 114, 0.5) 0%, rgba(9, 255, 196, 0.24) 100%)'
													: '#24282C',
												maxWidth: '300px',
											}}
										>
											<Typography
												style={{
													...styles.accountButtonText,
												}}
												overflow={'hidden'}
												textOverflow={'ellipsis'}
											>
												{user?.username &&
												user?.username !==
													user?.publicAddress
													? user?.username.charAt(0)
													: 'U'}
											</Typography>
										</Button>
										<Typography
											style={{
												...styles.linkText,
												wordBreak: 'break-word',
											}}
										>
											{is900to1400
												? getUserDisplayName(user)
														.length > 4
													? getUserDisplayName(
															user
													  ).slice(0, 4) + '..'
													: getUserDisplayName(user)
												: is1400to1600
												? getUserDisplayName(user)
														.length > 11
													? getUserDisplayName(
															user
													  ).slice(0, 11) + '..'
													: getUserDisplayName(user)
												: getUserDisplayName(
														user
												  ).slice(0, 25)}
										</Typography>
									</Stack>
								}
								style={{
									...styles.accordionContainer,
									padding: isMediumDesktop
										? '2px 2px 2px 4px'
										: '2px 4px 2px 6px',
								}}
								innerStyle={{
									padding: '0px',
								}}
								innerChildrenStyle={{
									padding: isMediumDesktop ? '2px' : '5px',
								}}
								handleChangeState={handleToggleAccount}
							>
								<Stack spacing={{ xs: 1, sm: 2, md: 1, xl: 2 }}>
									<Link
										to={`/user/${user?.id}`}
										onClick={handleCloseMenu}
									>
										<Typography
											style={{
												...styles.accountMyProfileText,
											}}
										>
											{`My profile`}
										</Typography>
									</Link>

									<Divider />

									{networkDialog}

									<Stack spacing={1}>
										<Stack
											direction={'row'}
											alignItems={'center'}
											spacing={2}
										>
											<Box
												style={{
													...styles.dialogButtonIconCircleSmall,
													background:
														CHAIN_MAP[
															chain?.chainId as number
														].icon.background,
												}}
											>
												<img
													src={
														CHAIN_MAP[
															chain?.chainId as number
														].icon.icon
													}
													height={
														chain?.chainId === 80085
															? 10
															: 16
													}
													style={{
														padding:
															chain?.chainId ===
															80085
																? '7px 4px'
																: '4px',
													}}
												/>
											</Box>
											<Typography
												style={{
													...styles.accountAccordionText,
												}}
											>
												{chain ? chain.name : 'Unknown'}
											</Typography>
										</Stack>
										<Typography
											style={{
												...styles.accountAccordionText,
												fontWeight: 700,
											}}
										>
											{`Connected - (${formatAddress(
												user?.publicAddress ?? ''
											)})`}
										</Typography>

										<LinkMat
											onClick={handleToggleDialog}
											style={{
												cursor: 'pointer',
											}}
										>
											<Typography
												style={{
													...styles.accountAccordionText,
												}}
											>
												{`Change`}
											</Typography>
										</LinkMat>
									</Stack>

									<Divider />

									<LinkMat
										href="https://postmint.canny.io/alpha-testing"
										target="_blank"
										rel="noreferrer"
										underline={'none'}
									>
										<Typography
											sx={{
												...styles.accountAccordionText,
												fontSize: '14px',
											}}
										>
											{`Report Bugs`}
										</Typography>
									</LinkMat>

									<Divider />

									<LinkMat
										href="https://app.termly.io/document/terms-of-use-for-online-marketplace/a2651e5a-8375-4223-b400-dca51a031289"
										target="_blank"
										rel="noreferrer"
										underline={'none'}
									>
										<Typography
											sx={{
												...styles.accountAccordionText,
												fontSize: '14px',
											}}
										>
											{`Terms and Conditions`}
										</Typography>
									</LinkMat>

									<Divider />

									<LinkMat
										href="https://app.termly.io/document/privacy-policy/291fce6f-5eac-47b9-844a-4f6b2375a75e"
										target="_blank"
										rel="noreferrer"
										underline={'none'}
									>
										<Typography
											sx={{
												...styles.accountAccordionText,
												fontSize: '14px',
											}}
										>
											{`Privacy Policy`}
										</Typography>
									</LinkMat>

									<Divider />

									<LinkMat
										underline={'none'}
										onClick={logoutUser}
										style={{
											cursor: 'pointer',
										}}
									>
										<Typography
											style={{
												...styles.accountAccordionText,
												fontSize: '14px',
												color: '#E4415C',
											}}
										>
											{`Log Out`}
										</Typography>
									</LinkMat>
								</Stack>
							</Accordion>
						) : null}
					</Stack>
					<Stack
						direction={'row'}
						alignItems={'center'}
						spacing={2}
						justifyContent={'center'}
						paddingBottom={2}
					>
						<LinkMat
							href="https://discord.gg/NN8UaxbYZP"
							target="_blank"
							rel="noreferrer"
							style={styles.socialLink}
						>
							<Icon>
								<img
									src={discordIconBlack}
									style={{ height: '1em' }}
								/>
							</Icon>
						</LinkMat>
						<LinkMat
							href="https://twitter.com/postmint_xyz"
							target="_blank"
							rel="noreferrer"
							style={styles.socialLink}
						>
							<XLogo size="1em" />
						</LinkMat>
					</Stack>
				</Stack>
			) : null}
		</Stack>
	);
};

const styles = {
	container: {
		backgroundColor: '#FCFCFD',
		border: '1px solid #E8ECF3',
		borderBottom: 'none',
	},
	linkButton: {
		padding: '9px 12px',
		borderRadius: '12px',
		border: '1px solid transparent',
		backgroundColor: 'transparent',
	} as CSSProperties,
	linkButtonActive: {
		border: '1px solid #BDF4DE',
		backgroundColor: '#EFFCF7',
	} as CSSProperties,
	linkText: {
		fontWeight: 500,
		fontSize: '14px',
		color: '#101828',
	} as CSSProperties,
	linkActive: {
		color: '#378868',
	} as CSSProperties,
	createCampaignButton: {
		padding: '18px 25px',
		height: '46px',
		'&:hover': {
			backgroundColor: '#000000',
			opacity: 0.8,
		},
	},
	createCampaignButtonText: {
		fontWeight: 500,
		fontSize: '14px',
		color: '#FFFFFF',
		textAlign: 'center',
	} as CSSProperties,
	marketplaceWidget: {
		backgroundColor: '#F2F4F7',
		borderRadius: '24px',
		padding: '16px',
	} as CSSProperties,
	marketplaceWidgetTitle: {
		fontFamily: 'Clash Display',
		fontWeight: 600,
		fontSize: '16px',
		color: '#101828',
	} as CSSProperties,
	marketplaceWidgetText: {
		fontWeight: 500,
		fontSize: '12px',
		color: '#667085',
	} as CSSProperties,
	marketplaceWidgetButton: {
		backgroundColor: '#5BE3AD',
		padding: '14px 8px',
		height: '36px',
	} as CSSProperties,
	marketplaceWidgetButtonText: {
		fontWeight: 600,
		fontSize: '14px',
		color: '#122D23',
	} as CSSProperties,
	socialLink: { display: 'flex', alignItems: 'center' } as CSSProperties,
	spinner: {
		marginRight: 5,
		color: 'black',
		paddingTop: 10,
		paddingBottom: 10,
	} as CSSProperties,
	accordionContainer: {
		border: '1px solid #E8ECF3',
		borderRadius: '12px',
		padding: '6px 4px 6px 6px',
		backgroundColor: '#FFFFFF',
	} as CSSProperties,
	connectWalletButton: {
		padding: '0 25px',
		'&:hover': {
			backgroundColor: '#000000',
			opacity: 0.8,
		},
	},
	connectWalletButtonText: {
		fontWeight: 700,
		fontSize: '16px',
		color: '#55E37D',
	} as CSSProperties,
	accountButton: {
		background:
			'radial-gradient(93.43% 794.53% at 16.45% 6.57%, rgba(85, 255, 114, 0.5) 0%, rgba(9, 255, 196, 0.24) 100%)',
		borderRadius: '50px',
		display: 'inline-flex',
		minWidth: '32px',
		height: '32px',
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	accountButtonText: {
		fontFamily: 'Gazpacho',
		fontWeight: 700,
		fontSize: '16px',
		color: '#4A5568',
	} as CSSProperties,
	accountAccordionText: {
		fontWeight: 500,
		fontSize: '12px',
		color: '#101828',
	} as CSSProperties,
	accountMyProfileText: {
		fontWeight: 500,
		fontSize: '14px',
		color: '#101828',
	} as CSSProperties,
	walletIcon: {
		backgroundColor: '#24282C',
		borderRadius: '100px',
	} as CSSProperties,
	dialogTitle: {
		fontFamily: 'Clash Display',
		fontWeight: 600,
		fontSize: '30px',
		color: '#101828',
	} as CSSProperties,
	dialogButton: {
		backgroundColor: 'transparent',
		'&:hover': {
			backgroundColor: 'transparent',
			opacity: 0.8,
		},
	},
	dialogButtonText: {
		fontWeight: 700,
		fontSize: '18px',
		color: '#27272E',
	} as CSSProperties,
	dialogButtonIconCircle: {
		height: '46px',
		width: '46px',
		borderRadius: '100px',
	} as CSSProperties,
	dialogButtonIconCircleSmall: {
		height: '24px',
		width: '24px',
		borderRadius: '100px',
	} as CSSProperties,
};

export default Sidebar;
