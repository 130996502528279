import './App.css';

import {
	CircularProgress,
	Container,
	Grid,
	PaletteMode,
	ThemeProvider,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
	Chain,
	ConnectButton,
	useAccount,
	useConnectKit,
	useParticleConnect,
	useParticleProvider,
	useSwitchChains,
} from '@particle-network/connect-react-ui';
import {
	CSSProperties,
	lazy,
	Suspense,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Navigate,
	Route,
	Routes,
	useLocation,
	useNavigate,
} from 'react-router-dom';

import type {} from '@mui/x-date-pickers/themeAugmentation';
import { ParticleChains } from '@particle-network/chains';
import {
	ArcElement,
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LineElement,
	LinearScale,
	PointElement,
	Title,
	Tooltip,
} from 'chart.js';
import { ethers } from 'ethers';
import { AppContext, CampaignContext } from '../../contexts';
import { ICampaign, ICommunityHubUser, IUser } from '../../interfaces';
import { IWeb2Client, getWeb2Client } from '../../web2/web2Client';
import { IWeb3Client, getWeb3Client } from '../../web3/web3Client';
import { CHAIN_MAP, DEFAULT_CHAIN_ID } from '../../web3/web3Utils';

import {
	IGroupedCampaignsByCreator,
	IGroupedCampaignsByStatus,
} from '../Dashboard/types';

const Login = lazy(() => import('../Login/Login'));
const NMHome = lazy(() => import('../NMHome/NMHome'));
const RTEHome = lazy(() => import('../RTEHome/RTEHome'));
const Dashboard = lazy(() => import('../Dashboard/Dashboard'));
const CampaignCreation = lazy(
	() => import('../CampaignCreation/CampaignCreation')
);
const Campaigns = lazy(() => import('../Campaigns/Campaigns'));
const CommunityProfile = lazy(
	() => import('../CommunityProfile/CommunityProfile')
);
const CommunityHub = lazy(() => import('../CommunityHub/CommunityHub'));
const CampaignInsights = lazy(
	() => import('../CampaignInsights/CampaignInsights')
);
const NewCampaign = lazy(() => import('../NewCampaign/NewCampaign'));
const Profile = lazy(() => import('../Profile/Profile'));
const Requests = lazy(() => import('../Requests/Requests'));
const Settings = lazy(() => import('../Settings/Settings'));

import { createUser, fetchUser, signInUser } from './utils';
import postmintLogo from '../../assets/token.svg';
import queryString from 'query-string';

ChartJS.register(
	ArcElement,
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

import { useToast } from '../../hooks';
import { MuiTheme } from '../../theme';
import Sidebar from '../../components/Sidebar';
import { ICampaignLeaderboardData } from '../Campaign/types';

let IS_PARTICLE_LOADING_GLOBAL = true;
let WAS_LOGIN_ATTEMPTED = false;

const loadingScreenContents = (
	<>
		<img alt="Postmint" src={postmintLogo} style={{ height: 80 }} />
		<CircularProgress size={25} style={{ color: '#49B68A' }} />
		<div style={{ display: 'none' }}>
			<ConnectButton.Custom>
				{({ accountLoading }) => {
					if (
						IS_PARTICLE_LOADING_GLOBAL &&
						accountLoading !== undefined
					) {
						IS_PARTICLE_LOADING_GLOBAL = accountLoading;
					}
					return <></>;
				}}
			</ConnectButton.Custom>
		</div>
	</>
);

const loadingScreenStyle = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	flexDirection: 'column',
	gap: 25,
} as CSSProperties;

const loadingScreen = (
	<div
		className="App"
		style={{
			...loadingScreenStyle,
			height: '100vh',
			width: '100vw',
		}}
	>
		{loadingScreenContents}
	</div>
);

const loadingScreenFallback = (
	<div
		className="App"
		style={{
			...loadingScreenStyle,
		}}
	>
		{loadingScreenContents}
	</div>
);

export const App = (): JSX.Element => {
	const isEthereumAvailable = window.ethereum !== undefined;

	const { disconnect } = useParticleConnect();
	const connectKit = useConnectKit();
	const { addToast } = useToast();

	const [theme, setTheme] = useState<PaletteMode>('dark');

	const [user, setUser] = useState<IUser | undefined>(undefined);

	const [isAxiosReady, setIsAxiosReady] = useState(false);
	const [isAuthenticating, setIsAuthenticating] = useState(false);
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [chainId, setChainId] = useState<number | undefined>(
		isEthereumAvailable ? undefined : DEFAULT_CHAIN_ID
	);
	const [wasLoginAttempted, setWasLoginAttempted] = useState(false);
	const [web3Client, setWeb3Client] = useState<IWeb3Client | undefined>(
		undefined
	);
	const [web2Client, setWeb2Client] = useState<IWeb2Client | undefined>(
		undefined
	);
	const [campaigns, setCampaigns] = useState<ICampaign[]>([]);
	const [statusCampaigns, setStatusCampaigns] =
		useState<IGroupedCampaignsByStatus>({});
	const [creatorCampaigns, setCreatorCampaigns] =
		useState<IGroupedCampaignsByCreator>({});
	const [communityHubs, setCommunityHubs] = useState<ICommunityHubUser[]>([]);
	const [campaignLeaderboard, setCampaignLeaderboard] = useState<
		ICampaignLeaderboardData[]
	>([]);
	const [isParticleLoading, setIsParticleLoading] = useState(
		IS_PARTICLE_LOADING_GLOBAL
	);
	const { isSwtichChain } = useSwitchChains();
	const particleProvider = useParticleProvider();
	const navigate = useNavigate();
	const location = useLocation();

	const [wasUserNotFound, setWasUserNotFound] = useState(false);

	const publicAddress = useAccount();

	// Initialize user login on provided chain
	useEffect(() => {
		if (
			publicAddress &&
			particleProvider &&
			chainId &&
			!isParticleLoading
		) {
			setIsAuthenticating(true);
			const chainConfig = CHAIN_MAP[chainId];
			if (chainConfig) {
				try {
					fetchUser(publicAddress)
						.then(async (returnedUser: IUser | undefined) => {
							let user = returnedUser;
							if (!user) {
								user = await createUser(publicAddress);
							}
							if (user) {
								if (publicAddress !== user.publicAddress) {
									setWasUserNotFound(true);
									setWasLoginAttempted(true);
									WAS_LOGIN_ATTEMPTED = true;
									await handleLoggedOut();
									setIsAuthenticating(false);
								} else {
									const userInfo =
										connectKit.particle.auth.getUserInfo();
									const web3Client = getWeb3Client(
										particleProvider,
										chainId,
										addToast
									);
									signInUser(
										publicAddress,
										userInfo,
										web3Client,
										user.nonce
									).then((token) => {
										if (token) {
											const web2Client = getWeb2Client(
												token,
												chainId
											);
											web2Client.users
												.getSelf()
												.then((user: IUser) => {
													setIsAuthenticated(true);
													setIsAxiosReady(true);
													setWeb3Client(web3Client);
													setWeb2Client(web2Client);
													setUser(user);
													setWasLoginAttempted(true);
													WAS_LOGIN_ATTEMPTED = true;
													setWasUserNotFound(false);
													setIsAuthenticating(false);
												})
												.catch(() => {
													setWasLoginAttempted(true);
													WAS_LOGIN_ATTEMPTED = true;
													handleLoggedOut();
													setIsAuthenticating(false);
												});
										} else {
											setWasLoginAttempted(true);
											WAS_LOGIN_ATTEMPTED = true;
											handleLoggedOut();
											setIsAuthenticating(false);
										}
									});
								}
							} else {
								setWasUserNotFound(true);
								setWasLoginAttempted(true);
								WAS_LOGIN_ATTEMPTED = true;
								handleLoggedOut();
								setIsAuthenticating(false);
							}
						})
						.catch((error) => {
							console.error(error);
							setWasUserNotFound(true);
							setWasLoginAttempted(true);
							WAS_LOGIN_ATTEMPTED = true;
							handleLoggedOut();
							setIsAuthenticating(false);
						});
				} catch (error) {
					console.error(error);
					setWasUserNotFound(true);
					setWasLoginAttempted(true);
					WAS_LOGIN_ATTEMPTED = true;
					handleLoggedOut();
					setIsAuthenticating(false);
				}
			}
		} else if (
			!isParticleLoading &&
			!publicAddress &&
			!wasLoginAttempted &&
			chainId
		) {
			setWasLoginAttempted(true);
			WAS_LOGIN_ATTEMPTED = true;
			setIsAuthenticating(false);
		}
	}, [particleProvider, publicAddress, chainId, isParticleLoading]);

	const handleChangeNetwork = useCallback(
		(chainId: number) => {
			if (isSwtichChain) {
				const particleChain = Object.values(ParticleChains).find(
					(chain) => chain.id === chainId
				);
				if (particleChain) {
					connectKit.switchChain(particleChain).then(() => {
						setChainId(chainId);
					});
				}
			}
		},
		[connectKit, isSwtichChain]
	);

	useEffect(() => {
		let interval: string | number | NodeJS.Timeout | undefined;
		const checkParticleLoaded = () => {
			if (!IS_PARTICLE_LOADING_GLOBAL) {
				setIsParticleLoading(IS_PARTICLE_LOADING_GLOBAL);
				clearInterval(interval);
			}
		};
		interval = setInterval(checkParticleLoaded, 500);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		const timeoutLoginAttempt = () => {
			if (!WAS_LOGIN_ATTEMPTED) {
				setWasLoginAttempted(true);
				WAS_LOGIN_ATTEMPTED = true;
				!isAuthenticated &&
					!isAuthenticating &&
					window.location.reload();
			}
		};
		const timeout = setTimeout(timeoutLoginAttempt, 2500);
		return () => clearTimeout(timeout);
	}, [isAuthenticated, isAuthenticating]);

	useEffect(() => {
		if (!chainId && window.ethereum) {
			let provider = new ethers.providers.Web3Provider(window.ethereum);
			provider.getNetwork().then(({ chainId }) => {
				setChainId(chainId);
			});
		}
	}, []);

	useEffect(() => {
		if (chainId && isSwtichChain) {
			const chainConfig = CHAIN_MAP[chainId];
			if (!chainConfig) {
				addToast(
					'postmint is currently available on Arbitum One and Polygon – please switch network.',
					'error'
				);
				handleChangeNetwork(DEFAULT_CHAIN_ID);
			}
		}
	}, [chainId, handleChangeNetwork, isSwtichChain]);

	useEffect(() => {
		async function chainChanged(chain?: Chain) {
			if (!chain || !CHAIN_MAP[chain.id]) {
				addToast(
					'postmint is currently available on Arbitum One and Polygon – please switch network back.',
					'error'
				);
				await handleChangeNetwork(DEFAULT_CHAIN_ID);
			} else {
				setChainId(chain.id);
			}
		}
		if (connectKit) {
			connectKit.on('chainChanged', chainChanged);
			return () => {
				connectKit.removeListener('chainChanged', chainChanged);
			};
		}
	}, [connectKit, handleChangeNetwork]);

	useEffect(() => {
		const walletChainId = connectKit.particle.auth.config.chainId;
		if (chainId && walletChainId && chainId !== walletChainId) {
			handleChangeNetwork(chainId);
		}
	}, [connectKit, chainId]);

	const handleLoggedOut = async () => {
		await disconnect({ hideLoading: true });
		await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/logoutUser`, {
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			credentials: 'include',
		});
		setIsAuthenticated(false);
		setUser(undefined);
		navigate('/login');
	};

	// Navigation useEffect
	useEffect(() => {
		if (wasLoginAttempted && !isAuthenticated && !isAuthenticating) {
			if (!location.pathname.includes('/referral')) {
				if (!location.pathname.includes('/join')) {
					navigate(
						`/login${
							location.pathname != '/' &&
							location.pathname != '/login' &&
							!location.search.includes('?redirect=')
								? `?redirect=${location.pathname}${location.search}`
								: ''
						}`
					);
				}
			}
		} else {
			const { redirect } = queryString.parse(window.location.search);
			redirect && navigate(`${redirect}`);
		}
	}, [wasLoginAttempted, isAuthenticated]);

	useEffect(() => {
		if (
			location.pathname.includes('/referral') ||
			location.pathname.includes('/join')
		) {
			document.body.style.backgroundColor = '#F5F5F5';
		} else {
			document.body.style.backgroundColor = '#0C111D';
		}
	}, [location]);

	const AppMUITheme = useMemo(() => {
		return {
			...MuiTheme,
			palette: {
				...MuiTheme.palette,
				mode: location.pathname.includes('/join') ? theme : 'light',
			},
		};
	}, [MuiTheme, theme, location]);

	useEffect(() => {
		if (
			location.pathname.includes('/referral') ||
			location.pathname.includes('/join')
		) {
			if (location.pathname.includes('/referral')) {
				document.body.style.backgroundColor = '#F5F5F5';
			} else {
				document.body.style.backgroundColor =
					theme === 'light' ? '#ffffff' : '#0C111D';
			}
		} else {
			document.body.style.backgroundColor = '#0C111D';
		}
	}, [location]);

	const mainRoutes = (
		<main>
			<Routes>
				<Route
					path="/campaigns/create"
					element={
						<Suspense fallback={loadingScreenFallback}>
							<CampaignCreation
								handleChangeNetwork={handleChangeNetwork}
							/>
						</Suspense>
					}
				/>
				<Route
					path="/campaigns/new"
					element={
						<Suspense fallback={loadingScreenFallback}>
							<NewCampaign
								handleChangeNetwork={handleChangeNetwork}
							/>
						</Suspense>
					}
				/>
				<Route
					path="/community/:creatorId/:campaignId/join"
					element={
						<Suspense fallback={loadingScreenFallback}>
							<NMHome
								IS_PARTICLE_LOADING_GLOBAL={
									IS_PARTICLE_LOADING_GLOBAL
								}
							/>
						</Suspense>
					}
				/>
				<Route
					path="/referral/:seed"
					element={
						<Suspense fallback={loadingScreenFallback}>
							<RTEHome />
						</Suspense>
					}
				/>
				<Route
					path="/community/:creatorId"
					element={
						<Suspense fallback={loadingScreenFallback}>
							<CommunityHub />
						</Suspense>
					}
				/>
				<Route
					path="/community/:creatorId/:campaignId"
					element={
						<Suspense fallback={loadingScreenFallback}>
							<CommunityProfile
								handleChangeNetwork={handleChangeNetwork}
							/>
						</Suspense>
					}
				/>
				<Route
					path="/campaigns/insights/:id/review?"
					element={
						<Suspense fallback={loadingScreenFallback}>
							<CampaignInsights />
						</Suspense>
					}
				/>
				<Route
					path="/campaigns/insights/:id/allocate?"
					element={
						<Suspense fallback={loadingScreenFallback}>
							<CampaignInsights />
						</Suspense>
					}
				/>
				<Route
					path="/profile"
					element={
						<Suspense fallback={loadingScreenFallback}>
							<Settings />
						</Suspense>
					}
				/>
				<Route
					path="/user/:userId" // Public Profile
					element={
						<Suspense fallback={loadingScreenFallback}>
							<Profile />
						</Suspense>
					}
				/>
				<Route
					path="/user" // My profile
					element={
						<Suspense fallback={loadingScreenFallback}>
							<Profile />
						</Suspense>
					}
				/>
				<Route
					path="/my-requests"
					element={
						<Suspense fallback={loadingScreenFallback}>
							<Requests />
						</Suspense>
					}
				/>
				<Route
					path="/campaign-offers/:creatorId/:campaignId"
					element={
						<Suspense fallback={loadingScreenFallback}>
							<Requests />
						</Suspense>
					}
				/>
				{/* <Route
					path="/:tab"
					element={
						<Suspense fallback={loadingScreenFallback}>
							<Dashboard />
						</Suspense>
					}
				/>
				<Route
					path="/campaigns/:tab"
					element={
						<Suspense fallback={loadingScreenFallback}>
							<Campaigns />
						</Suspense>
					}
				/> */}
				<Route
					path="/*"
					element={
						<Navigate
							to={`/community/65946fb16301a701464371f3/65b1768d02a1b944d9e19648`}
							replace={true}
						/>
					}
				/>
			</Routes>
		</main>
	);

	const hasNoContainer =
		location.pathname.includes('/community') ||
		location.pathname.includes('/campaigns/insights/') ||
		location.pathname.includes('/profile') ||
		location.pathname.includes('/user');

	const mainContaier = (
		<>
			{hasNoContainer ? (
				mainRoutes
			) : (
				<Container
					{...(!hasNoContainer
						? {
								fixed: true,
								maxWidth: false,
						  }
						: {
								fixed: false,
								maxWidth: 'xl',
						  })}
					sx={{ paddingBottom: '1px', display: 'block' }}
				>
					{mainRoutes}
				</Container>
			)}
		</>
	);

	return wasLoginAttempted && !isParticleLoading && !isAuthenticating ? (
		<ThemeProvider theme={AppMUITheme}>
			{isAxiosReady && particleProvider && !wasUserNotFound && user ? (
				<AppContext.Provider
					value={{
						isAuthenticated,
						chain: chainId ? CHAIN_MAP[chainId] : undefined, // Why is network allowed to be undefined?
						user,
						setUser,
						logoutUser: handleLoggedOut,
						web3Client,
						web2Client,
						theme,
						setTheme,
					}}
				>
					<CampaignContext.Provider
						value={{
							campaigns,
							statusCampaigns,
							creatorCampaigns,
							communityHubs,
							campaignLeaderboard,
							setCampaigns,
							setStatusCampaigns,
							setCreatorCampaigns,
							setCommunityHubs,
							setCampaignLeaderboard,
						}}
					>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<div
								className="App"
								style={
									location.pathname.includes('/referral')
										? { backgroundColor: '#B8DE79' }
										: location.pathname.includes('/join')
										? {
												backgroundColor:
													theme === 'light'
														? '#ffffff'
														: '#0C111D',
												height: '100%',
										  }
										: {}
								}
							>
								<div className="App-content">
									{location.pathname.includes('/referral') ||
									location.pathname.includes('/join') ||
									location.pathname.includes(
										'/campaigns/create'
									) ? (
										mainContaier
									) : (
										<Grid container>
											<Grid item xs={12} md={2}>
												<Sidebar
													handleChangeNetwork={
														handleChangeNetwork
													}
												/>
											</Grid>
											<Grid item xs={12} md={10}>
												{mainContaier}
											</Grid>
										</Grid>
									)}
								</div>
							</div>
						</LocalizationProvider>
					</CampaignContext.Provider>
				</AppContext.Provider>
			) : (
				<div
					className="App"
					style={
						location.pathname.includes('/referral')
							? { backgroundColor: '#B8DE79' }
							: location.pathname.includes('/join')
							? {
									backgroundColor:
										theme === 'light'
											? '#ffffff'
											: '#0C111D',
									minHeight: '100vh',
							  }
							: {}
					}
				>
					<Container maxWidth="xl" style={{ minHeight: '100vh' }}>
						<main>
							<AppContext.Provider
								value={{
									isAuthenticated,
									chain: chainId
										? CHAIN_MAP[chainId]
										: undefined,
									user,
									setUser,
									logoutUser: handleLoggedOut,
									web3Client,
									web2Client,
									theme,
									setTheme,
								}}
							>
								<Routes>
									<Route
										path="/community/:creatorId/:campaignId/join"
										element={
											<Suspense
												fallback={loadingScreenFallback}
											>
												<NMHome
													IS_PARTICLE_LOADING_GLOBAL={
														IS_PARTICLE_LOADING_GLOBAL
													}
												/>
											</Suspense>
										}
									/>
									<Route
										path="/referral/:seed"
										element={
											<Suspense
												fallback={loadingScreenFallback}
											>
												<RTEHome />
											</Suspense>
										}
									/>
									<Route
										path="/*"
										element={
											<Suspense
												fallback={loadingScreenFallback}
											>
												<Login
													IS_PARTICLE_LOADING_GLOBAL={
														IS_PARTICLE_LOADING_GLOBAL
													}
												/>
											</Suspense>
										}
									/>
								</Routes>
							</AppContext.Provider>
						</main>
					</Container>
				</div>
			)}
		</ThemeProvider>
	) : (
		loadingScreen
	);
};
